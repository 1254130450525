<template>
  <div class="methods-container">
    <AppCard sheet class="methods-empty-payment">
      <template #header> </template>
      <AppCardImageTitle
        class="image"
        :src="require('@galileo/assets/images/illustrations/card.svg')"
        alt="Payment option"
        :title="$t('ComponentNoPaymentMethods.TitleText').value"
      >
        <p v-if="isCorporateAccount">
          {{ $t('ComponentNoPaymentMethods.CorporateDescription').value }}
        </p>
        <p v-else>{{ $t('ComponentNoPaymentMethods.DescriptionText').value }}</p>
      </AppCardImageTitle>

      <AppButton v-if="!isCorporateAccount" theme="primary" @click="sendMoney">
        {{ $t('ComponentNoPaymentMethods.ButtonStartTransfer').value }}
      </AppButton>

      <AppButton v-else theme="primary" @click="$emit('addPaymentMethod')">
        {{ $t('AccountPaymentMethods.AddPaymentMethodButton').value }}
      </AppButton>
    </AppCard>
  </div>
</template>

<script>
import { useRouter } from '@galileo/composables/useRouter'
import { IconAdd } from '@oen.web.vue2/icons'
import ButtonGroup from '@galileo/components/ButtonGroup/ButtonGroup'
import { ref } from '@vue/composition-api'
import { storeToRefs } from 'pinia'

import { AppCard, AppCardImageTitle, AppButton, AppIcon } from '@oen.web.vue2/ui'

import { useI18nStore, useSendMoneyStore, useAuthStore } from '@galileo/stores'

export default {
  name: 'NoPaymentMethods',
  components: {
    AppButton,
    AppCard,
    AppCardImageTitle,
    ButtonGroup,
    IconAdd,
    AppIcon,
  },
  props: {},
  emits: ['addPaymentMethod'],
  setup() {
    const router = useRouter()
    const { $t } = useI18nStore()
    const authStore = useAuthStore()
    const sendMoneyStore = useSendMoneyStore()
    const { isCorporateAccount } = storeToRefs(authStore)

    const sendMoney = () => {
      sendMoneyStore.restoreDefaultForm()
      router.push('/send-money')
    }

    return {
      sendMoney,
      isCorporateAccount,
      $t,
    }
  },
}
</script>

<style scoped>
.methods-container {
  @apply mt-0;
  @screen sm {
    @apply mt-12;
  }
  .methods-empty {
    @apply border-blue-lighter border-solid border;
    ::v-deep .card-content {
      @apply py-4;
    }
  }
  .methods-empty-payment {
    ::v-deep .card-content {
      @apply py-12;
    }
  }
  .methods-empty-payment.card--has-image-title.card--has-header {
    ::v-deep .card-content {
      @apply mt-0;
    }
    ::v-deep .card-content-block {
      @apply mb-0;
    }
    ::v-deep .card-image-title-image {
      @apply shadow-ria-1;
      padding: 1.5rem;
      border-radius: 50%;
    }
  }
  .image {
    ::v-deep .card-image-title-image {
      @apply mb-4;
    }
  }
  .no-payment-methods-button {
    @apply w-auto !important;
    @apply mx-auto;
    @apply bg-blue-light;
    ::v-deep .button-inner {
      @apply py-2;
    }
  }
}
</style>
