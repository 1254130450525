<template>
  <div>
    <!-- Loading -->
    <AppSpinnerBig :loading="loading" />
    <LayoutDashboardPage v-if="!loading" title="">
      <AccountNoPaymentMethods
        v-if="!allPaymentMethods.length && !loading"
        :is-send-money="false"
        @addPaymentMethod="addPaymentMethod"
      />

      <AppCard
        v-if="allPaymentMethods.length > 0"
        class="payment-methods-app-card"
        :ignoreFigureTitle="true"
      >
        <template v-if="!isCorporateAccount" #header>
          <AppCardHeader>
            <h2 class="payment-methods-title-text">
              {{ $t('PageAccountPaymentMethods.Title').value }}
            </h2>
          </AppCardHeader>
        </template>

        <div class="title-box" v-if="isCorporateAccount">
          <AppCardFigureTitle
            class="payment-methods-title-text-with-add"
            :title="$t('AccountPaymentMethods.PaymentMethodsTitle').value"
          />

          <AppButton
            class="xe-add-button"
            theme="secondary"
            analytics-name="add-payment-method"
            @click="addPaymentMethod"
          >
            <template #leftIcon>
              <AppIcon name=" ">
                <IconAdd />
              </AppIcon>
            </template>
            {{ $t('AccountPaymentMethods.AddPaymentMethodButton').value }}
          </AppButton>
        </div>

        <div v-if="cardMethods && cardMethods.length > 0">
          <AppList class="xe-category-list skip-margin-bottom">
            <PaymentMethods :payment-methods="cardMethods" :is-override-action-menu="true" />
          </AppList>
        </div>
        <div v-if="bankMethods && bankMethods.length > 0">
          <XeAddListItem
            v-if="isCorporateAccount"
            class="xe-list-item-button"
            :select="addPaymentMethod"
            :text="$t('AccountPaymentMethods.AddPaymentMethodButton').value"
          />
          <AppList class="xe-category-list">
            <PaymentMethods :payment-methods="bankMethods" :is-override-action-menu="true" />
          </AppList>
        </div>
      </AppCard>

      <!-- Renders AppModal, children routes to be configured in vue router -->
      <ModalRouterView />
    </LayoutDashboardPage>
  </div>
</template>

<script>
import { ref, onBeforeMount, computed, watch } from '@vue/composition-api'

import { storeToRefs } from 'pinia'

import AccountNoPaymentMethods from '@galileo/components/Views/PaymentMethod/NoPaymentMethods/AccountNoPaymentMethods'
import NoPaymentMethods from '@galileo/components/Views/PaymentMethod/NoPaymentMethods'

import ModalRouterView from '@galileo/views/ModalRouterView'
import LayoutDashboardPage from '@galileo/components/LayoutDashboardPage/LayoutDashboardPage'
import { SEGMENT_EVENTS, SEGMENT_LOCATIONS } from '@galileo/constants/segmentAnalytics'
import { useAddPaymentMethods } from '@galileo/composables/usePaymentHelpers'
import PaymentMethods from '@galileo/components/Views/PaymentMethod/PaymentMethods.vue'
import { IconAdd } from '@oen.web.vue2/icons'
import XeAddListItem from '@galileo/components/XeAddListItem/XeAddListItem.vue'

import {
  AppCard,
  AppCardHeader,
  AppList,
  AppSpinnerBig,
  AppCardFigureTitle,
  AppIcon,
  AppButton,
} from '@oen.web.vue2/ui'

import { useAuthStore, useI18nStore, useAnalyticsStore, usePaymentsStore } from '@galileo/stores'
import { PAYMENT_METHODS } from '@galileo/constants/sendMoneyFlow.const'

export default {
  name: 'AccountPaymentMethods',
  components: {
    AppCard,
    AppCardHeader,
    AppSpinnerBig,
    AppList,
    NoPaymentMethods,
    PaymentMethods,
    ModalRouterView,
    LayoutDashboardPage,
    AccountNoPaymentMethods,
    AppCardFigureTitle,
    AppIcon,
    IconAdd,
    AppButton,
    XeAddListItem,
  },
  setup() {
    const { $t } = useI18nStore()
    const analyticsStore = useAnalyticsStore()
    const authStore = useAuthStore()
    const paymentsStore = usePaymentsStore()
    const { isCorporateAccount } = storeToRefs(authStore)

    const loading = ref(true)
    const bankPayment = 'Any'

    let bankMethods = ref([])
    let cardMethods = ref([])

    const allPaymentMethods = computed(() => {
      let result = paymentsStore.allPaymentMethods
      return result
    })

    watch(allPaymentMethods, (tempPaymentMethods) => {
      bankMethods.value = tempPaymentMethods.filter(
        (payment) => payment.paymentMethod === bankPayment
      )
      cardMethods.value = tempPaymentMethods.filter((payment) =>
        [PAYMENT_METHODS.CREDIT_CARD, PAYMENT_METHODS.DEBIT_CARD].includes(payment.paymentMethod)
      )
    })

    onBeforeMount(async () => {
      await paymentsStore.getPaymentMethods()

      if (allPaymentMethods.value.length > 0) {
        const validCards = allPaymentMethods.value.filter(
          (method) => method.cardV2Data?.cardState === 'Active'
        )?.length

        const invalidCards = allPaymentMethods.value.filter(
          (method) => method.cardV2Data?.cardState === 'Invalid'
        )?.length

        const expiredCards = cardMethods.value.filter((method) => method.isExpired).length

        const verifiedDdAccounts = bankMethods.value.filter(
          (method) => method.verificationStatus === 'Verified'
        ).length

        const nonVerifedDdAccounts = bankMethods.value.filter(
          (method) => method.verificationStatus !== 'Verified'
        ).length

        analyticsStore.track({
          event: SEGMENT_EVENTS.SAVED_PAYMENT_METHODS,
          traits: {
            totalCards: cardMethods.value.length,
            validCards: validCards,
            invalidCards: invalidCards,
            expiredCards: expiredCards,
            totalDdccounts: bankMethods.value.length,
            verifiedDdAccounts: verifiedDdAccounts,
            nonVerifedDdAccounts: nonVerifedDdAccounts,
            location: SEGMENT_LOCATIONS.ACCOUNT,
          },
        })
      }
      loading.value = false
    })

    analyticsStore.setPaymentLocation(SEGMENT_LOCATIONS.ACCOUNT)
    analyticsStore.track({
      event: SEGMENT_EVENTS.PAYMENT_METHODS_ACCESSED,
      traits: {
        location: SEGMENT_LOCATIONS.ACCOUNT,
      },
    })

    const breadcrumbs = [
      {
        name: $t('PageAccountPaymentMethods.BreadcrumbNameAccount'),
        path: '/account',
      },
      {
        name: $t('PageAccountPaymentMethods.BreadcrumbNamePayment'),
        path: '/payment-methods',
      },
    ]

    const isSendMoney = false

    const { addPaymentMethod } = useAddPaymentMethods(isSendMoney)

    return {
      loading,
      allPaymentMethods,
      addPaymentMethod,
      $t,
      breadcrumbs,
      bankMethods,
      cardMethods,
      isCorporateAccount,
    }
  },
}
</script>

<style scoped>
.card {
  @apply border-none;
  min-width: 0px;
}
@screen sm {
  .card {
    min-width: 640px;
  }
}

.payment-methods-title-text {
  @apply text-2xl leading-8;
}

.card-header {
  @apply py-12;
  @apply flex;
}

@media only screen and (max-width: 600px) {
  .card-header {
    @apply hidden;
  }
}

::v-deep .payment-title {
  font-size: 15px;
  @apply font-medium leading-6;
}

::v-deep .payment-number {
  font-size: 15px;
  @apply font-medium leading-6;
}

@screen xs {
  ::v-deep .list-item-left {
    justify-content: space-between !important;
    display: block !important;
  }
}

@screen md {
  ::v-deep .list-item-left {
    display: block !important;
  }
}

@screen xs {
  .xe-category-list {
    border-top-width: 0px;
    .list {
      @apply mt-0 !important;
    }
  }
  ::v-deep .list {
    @apply mt-0 !important;
  }
}

@screen md {
  ::v-deep .xe-category-list {
    border-radius: 0px;
    border-top-width: 0px;
    border-bottom-width: 1px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-color: #f3f4f6;
  }
}

.xe-category-list.skip-margin-bottom {
  @apply mb-0;
}

.list-item-caption {
  @apply text-sm;
  @apply font-normal;
}
.payment-methods-app-card {
  @apply mb-12 mx-auto;
  /* @apply max-w-3xl; */
  @apply h-auto !important;
}

::v-deep .title-box {
  @apply flex items-start justify-between mb-12;
  .card-figure-title-title {
    @apply text-2xl;
    font-weight: var(--theme-card-header-title-font-weight, theme('fontWeight.medium'));
  }

  .button {
    @apply w-auto;
    .button-inner {
      white-space: nowrap;
    }
  }
}

.recipients-app-card {
  display: none;
  @apply mt-0 mb-0 mx-auto;
  @apply max-w-3xl;
  @apply h-auto !important;
}

.payment-methods-title-text-with-add {
  @apply w-full block;

  ::v-deep {
    .card-figure-title-title {
      @apply font-medium;
    }
  }
}

@screen sm {
  .margin-spacer {
    display: block;
  }

  .recipients-app-card {
    @apply mt-12;
    @apply mt-0 mb-12;

    @screen sm {
      ::v-deep .card {
        @apply overflow-visible !important;
      }
    }
  }
}

.xe-list-item-button {
  @apply items-center w-full mt-0;

  @screen md {
    @apply hidden;
  }
}
</style>
